const $ = require("jquery")
const Barba = require("barba.js")
const NAV_ACTIVE = "rkt.setNavActive."
const NAV_A = NAV_ACTIVE+"a"
const NAV_HREF = NAV_ACTIVE+"href"

var allItems, $items, $item, $a, targetOptions, $parents, currentStatus, currentUrl,
	prefix = window.location.protocol+"//"+window.location.hostname,
	port = window.location.port ? ":"+window.location.port : ""

function init(options)
{
	allItems = options

	$.each(options, function(target, targetOptions){
		$items = $(target)
		if(typeof targetOptions == "string") allItems[target] = targetOptions = { addClass: targetOptions }
		targetOptions.$items = $items
		targetOptions.addClass = targetOptions.addClass ? targetOptions.addClass : "active"
		if(targetOptions.parents) targetOptions.parents.addClass = targetOptions.parents.addClass ? targetOptions.parents.addClass : "child-active"
		$items.each(function(){
			$item = $a = $(this)
			if(targetOptions.a) $a = $item.find(targetOptions.a)
			$item.data(NAV_A, $a)
			$a.data(NAV_HREF, prefix+port+$a.attr("href"))
		})
	})

	update()
}

function update(){
	currentStatus = Barba.HistoryManager.currentStatus()
	if(!currentStatus) return
	currentUrl = currentStatus.url

	$.each(allItems, function(target, targetOptions){
		$parents = false
		targetOptions.$items.each(function(i, e){
			$item = $(e)
			$a = $item.data(NAV_A)
			if(
				(!targetOptions.exactMatch && currentUrl.indexOf($a.data(NAV_HREF)) === 0) ||
				currentUrl == $a.data(NAV_HREF)
			){
				$item.addClass(targetOptions.addClass)
				if(targetOptions.parents)
					$parents = $item.parentsUntil(
						targetOptions.parents.top,
						targetOptions.parents.element
					)
			}
			else {
				$item.removeClass(targetOptions.addClass)
			}
		})

		if(targetOptions.parents){
			if($parents) $parents.addClass(targetOptions.parents.addClass)
			$items.not($parents).removeClass(targetOptions.parents.addClass)
		}
	})

}

module.exports = {
	init: init,
	update: update
}
