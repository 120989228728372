const $ = require("jquery")
const Barba = require("barba.js")


module.exports = function(options){

	const settings = {
		// defaults here
	}

	var active = false
	const publics = {
		disabled: false,
		toggle,
		open,
		close,
		isOpen
	}

	// merge in options
	$.extend(true, settings, options)

	settings.$setActive = $(settings.setActive)

	// store texts
	$.each(settings.text, function(target, value){
		settings.text[target] = {active: value, inactive:$(target).text()}
	})

	// store htmls
	$.each(settings.html, function(target, value){
		settings.html[target] = {active: value, inactive:$(target).html()}
	})

	$(settings.trigger).on("click", toggle)

	$(settings.autoClose).on("click", function(){
		if(active) close()
	})

	function set(state){
		if(publics.disabled) return
		active = state

		// set texts
		$.each(settings.text, function(target, value){
			if(active)
				$(target).text(value.active)
			else
				$(target).text(value.inactive)
		})

		// set htmls
		$.each(settings.html, function(target, value){
			if(active)
				$(target).html(value.active)
			else
				$(target).html(value.inactive)
		})

		// set classes
		$.each(settings.class, function(target, value){
			if(active)
				$(target).addClass(value)
			else
				$(target).removeClass(value)
		})

		// set actives
		if(active)
			settings.$setActive.addClass("active")
		else
			settings.$setActive.removeClass("active")
	}

	function open(){ set(true) }
	function close(){ set(false) }
	function toggle(){ set(!active) }
	function isOpen(){ return active }

	return publics
}
