const Barba = require('barba.js')
const $ = require('jquery')
const navController = require('./utils/JS/navController')
const setNavActive = require('./utils/JS/setNavActive').init
const preload = require('./utils/JS/preload')
const ProgressBar = require('progressbar.js')

require('jquery.easing')

const $document = $(document)
const $window = $(window)


$document.ready(() => {

	const bar = new ProgressBar.Line('#preload-bar', {easing: 'easeInOut'})
	const $bar = $("#preload-bar").css({display:"none"})
	var $overlay = $("#preload-overlay")

	preload.init({
		start: function(){
			bar.set(0.33)
			$bar.fadeIn(400)
		},
		progress: function(event){
			bar.set(0.3333+(event.progress*0.6666))
		},
		complete: function(){
			bar.set(1)
			$bar.fadeOut(400)
			if($overlay){
				$overlay.fadeOut(500, function() {
					$overlay = false
				})
			}
		}
	})

	Barba.Pjax.start()

	Barba.Dispatcher.on("newPageReady", function(c, p, html) {

		navController({
			trigger: ".nav-trigger, .nav-close",
			setActive: "nav",
			autoClose: "nav a"
		})

	})

	navController({
		trigger: ".nav-trigger, .nav-close",
		setActive: "nav",
		autoClose: "nav a"
	})

	setNavActive({
		"header .nav li": {
			a: "> a",
			parents: { top: ".nav", element:"li" }
		}
	})

})
