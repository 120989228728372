const Barba = require('barba.js')
const $ = require('jquery')

Barba.BaseView.extend({
	namespace: 'australia',
	onEnterCompleted: function() {
		var navPos = 0
		navPos = $('.nav-trigger').offset().top

		$(window).on('load scroll', function(){
			var scrollPos = $(this).scrollTop()
			if (scrollPos >= navPos ) {
				$('.nav-trigger').addClass('sticky')
			} else {
				$('.nav-trigger').removeClass('sticky')
			}
		})

		$('a[href*="#"]')
			// Remove links that don't actually link to anything
			.not('[href="#"]')
			.not('[href="#0"]')
			.click(function(event) {
				// On-page links
				if (
					location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
					&&
					location.hostname == this.hostname
				) {
					// Figure out element to scroll to
					var target = $(this.hash);
					target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
					// Does a scroll target exist?
					if (target.length) {
						// Only prevent default if animation is actually gonna happen
						event.preventDefault();
						$('html, body').animate({
							scrollTop: target.offset().top
						}, 1000, 'easeInOutQuart', function() {
							// Callback after animation
							// Must change focus!
							var $target = $(target);
							$target.focus();
							if ($target.is(":focus")) { // Checking if the target was focused
								return false;
							} else {
								$target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
								$target.focus(); // Set focus again
							};
						});
					}
				}
			});




	},
	onLeave: function() {
		//
	}
}).init()
