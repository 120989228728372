//const bugsnag = require('bugsnag-js');
//global.bugsnag = bugsnag(BUGSNAG_APIKEY);
// jQuery (defined as a global since so many plugins look for it in the global scope)
global.jQuery = require('jquery')

// global Barba
global.Barba = require('barba.js')

// rkt utils JS library
require('./utils/_rkt')
require('./css/_index')

// stop back button popping the scroll position
require('scroll-restoration-polyfill')
history.scrollRestoration = 'manual'

// Require js files in source/js here
require('./barba/transition.fade')
require('./barba/view.australia')
require('./barba/view.pizza-bar')
require('./barba/view.charcuterie')
require('./main')
