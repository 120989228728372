const $ = require('jquery')
const Barba = require('barba.js')
require('preload-js')

const SRC = "src"
const PRELOAD_SRC = "data-preload-src"
const PRELOAD_BG = "data-preload-bg"

var queue, mainOptions, rkt, deferred, initialPreloadDone = false, enabled = true

// init from main js file
function init(options){
	mainOptions = options
}

// rkt callback for load complete
function rkt(func){
	rkt = func
}

// when Barba link clicked, run any start functions
Barba.Dispatcher.on('initStateChange', function() {
	if(!enabled) return
	if(deferred) deferred.reject()
	deferred = Barba.Utils.deferred()
	if(mainOptions && mainOptions.start) mainOptions.start()
})

// main load function
function load(options){
	if(!enabled) return
	initialPreloadDone = true

	if(queue){
		queue.reset()
		queue.removeAll()
	}

	if(!deferred) deferred = Barba.Utils.deferred()

	var $element =  options && options.element ? $(options.element) : $("body"),
		preloadItems = [], preloadItemsLength, i, item, $item, src,
		queue = new createjs.LoadQueue()

	queue.setMaxConnections(5)

	// loop through all items and look for img and bg image data attributes
	$element.find("["+PRELOAD_SRC+"], ["+PRELOAD_BG+"]").each(function(){
		src = this.getAttribute(PRELOAD_SRC) ? this.getAttribute(PRELOAD_SRC) : this.getAttribute(PRELOAD_BG)
		if(!src) return
		src = src.trim()
		if(src.length < 2) return
		queue.loadFile(src, false)
		preloadItems.push(this)
	})

	preloadItemsLength = preloadItems.length
	if(preloadItemsLength < 1) {
		complete(options)
		return deferred.promise
	}

	queue.on("progress", function(event){
		if(mainOptions && mainOptions.progress) mainOptions.progress(event)
		if(options && options.progress) options.progress(event)
	})

	queue.on("complete", function(){
		// loop through all the items and assign the actual src/style properties from the data attrs
		for(i = 0; i<preloadItemsLength; i++){
			item = preloadItems[i]
			if(item.hasAttribute(PRELOAD_SRC))
				item.setAttribute("src", item.getAttribute(PRELOAD_SRC))
			else if(item.hasAttribute(PRELOAD_BG))
				$(item).css("background-image", 'url("'+item.getAttribute(PRELOAD_BG)+'")' )
		}

		// wait a little for the images to be loaded from cache
		setTimeout(()=>{ complete(options) }, 50)
	})

	queue.load()

	return deferred.promise
}

function complete(options){
	if(mainOptions && mainOptions.complete) mainOptions.complete()
	if(options && options.complete) options.complete()
	if(rkt) rkt()
	if(deferred) deferred.resolve()
}

function isDone(){
	if(deferred) return deferred.promise
	var d = Barba.Utils.deferred()
	d.resolve()
	return d.promise
}

module.exports = {
	init: init,
	rkt: rkt,
	load: load,
	isDone: isDone,
	initialPreloadDone: () => initialPreloadDone,
	enable: () => enabled = true,
	disable: () => enabled = false
}
